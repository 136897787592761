<template>
  <h2>基本配置</h2>

  <form action="" @submit.prevent="saveCustomerInfo()">
    <div class="card mb-5">
      <div class="card-header">
        <div class="h5 d-inline mb-0">客戶資訊</div>
        <button type="submit" class="btn btn-success float-end">儲存</button>
      </div>
      <div class="card-body">
        <div class="mb-3">
          <label class="form-label">電話</label>
          <input type="text" class="form-control" v-model="customerInfo.tel" />
        </div>
        <div class="mb-3">
          <label class="form-label">連絡信箱</label>
          <input
            type="text"
            class="form-control"
            v-model="customerInfo.email"
          />
        </div>
        <div class="mb-3">
          <label class="form-label">LINE@</label>
          <input
            type="text"
            class="form-control"
            v-model="customerInfo.lineAt"
          />
        </div>
        <div class="mb-3">
          <label class="form-label">Facebook網址</label>
          <input
            type="text"
            class="form-control"
            v-model="customerInfo.facebook"
          />
        </div>
      </div>
    </div>
  </form>

  <div class="card mb-5">
    <div class="card-header">
      <div class="h5 d-inline mb-0">店家資訊</div>
      <!-- <button type="submit" class="btn btn-success float-end">儲存</button> -->
      <button
        @click="initMemberRole()"
        class="ms-5"
        v-if="$store.state.adminUserRole === 'sys'"
      >
        init
      </button>
    </div>
    <div class="card-body">
      <!-- {{ storeAction }} -->
      <div class="mb-3">
        <label class="form-label">
          服務店家
          <button
            type="button"
            class="btn btn-secondary btn-sm rounded-circle ms-3"
            @click="showModal('add')"
          >
            <i class="bi bi-plus-lg"></i>
          </button>
        </label>

        <!-- {{ stores }} -->

        <draggable
          v-model="stores"
          class="mt-3"
          handle=".handle"
          ghost-class="ghost"
          @sort="onStoresSrot"
          itemKey="index"
        >
          <template #item="{ element }">
            <span>
              <div
                class="btn-group border me-4 mb-2"
                role="group"
                aria-label="Basic example"
              >
                <button type="button" class="btn btn-light px-1 handle">
                  <i class="bi bi-three-dots-vertical"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="showModal('edit', element)"
                >
                  {{ element }}
                </button>
              </div>
            </span>
          </template>
        </draggable>

        <!-- <div class="mt-3">
          <button
            v-for="(store, index) in stores"
            :key="index"
            type="button"
            class="btn btn-outline-primary me-3 mb-2"
            @click="showModal('edit', store)"
          >
            {{ store }}
          </button>
        </div> -->
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">店家資訊管理</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <label class="form-label">請輸入店家名稱</label>
          <!-- <div>
            storeName: {{ storeName }} <br>
            nowEditStoreName: {{ nowEditStoreName }}
          </div> -->
          <input class="form-control" type="text" v-model="storeName" />
        </div>
        <div class="modal-footer d-flex justify-content-between">
          <div>
            <button
              type="button"
              class="btn btn-danger"
              v-if="storeAction == 'edit'"
              @click="delStore()"
            >
              刪除店家
            </button>
          </div>
          <div>
            <button type="button" class="btn btn-success" @click="saveStore()">
              儲存
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <form action="" @submit.prevent="saveRatio()">
    <div class="card">
      <div class="card-header">
        <div class="h5 d-inline mb-0">分潤比例</div>
        <div class="float-end">
          <i class="bi bi-exclamation-square-fill"></i>
          已結算分潤將不受影響
          <button class="btn btn-success">儲存</button>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-3">
            <div class="mb-3">
              <label class="form-label">下線門檻</label>
              <div class="input-group">
                <input
                  type="number"
                  min="0"
                  class="form-control"
                  v-model.number="profitSharingRatio.basic.numberOfPeople"
                />
                <span class="input-group-text">人</span>
              </div>
            </div>
            <div class="mb-3">
              <label class="form-label">第一代下線分潤</label>
              <div class="input-group">
                <input
                  type="number"
                  min="0"
                  class="form-control"
                  v-model.number="profitSharingRatio.basic.rate1"
                />
                <span class="input-group-text">%</span>
              </div>
            </div>
            <div class="mb-3">
              <label class="form-label">第二代下線分潤</label>
              <div class="input-group">
                <input
                  type="number"
                  min="0"
                  class="form-control"
                  v-model.number="profitSharingRatio.basic.rate2"
                />
                <span class="input-group-text">%</span>
              </div>
            </div>
            <div class="mb-3">
              <label class="form-label">第三代下線分潤</label>
              <div class="input-group">
                <input
                  type="number"
                  min="0"
                  class="form-control"
                  v-model.number="profitSharingRatio.basic.rate3"
                />
                <span class="input-group-text">%</span>
              </div>
            </div>
          </div>
          <div class="col-md-3 offset-md-1">
            <div class="mb-3">
              <label class="form-label">增益分潤下線門檻</label>
              <div class="input-group">
                <input
                  type="number"
                  min="0"
                  class="form-control"
                  v-model.number="profitSharingRatio.gain.numberOfPeople"
                />
                <span class="input-group-text">人</span>
              </div>
            </div>
            <div class="mb-3">
              <label class="form-label">第一代下線分潤</label>
              <div class="input-group">
                <input
                  type="number"
                  min="0"
                  class="form-control"
                  v-model.number="profitSharingRatio.gain.rate1"
                />
                <span class="input-group-text">%</span>
              </div>
            </div>
            <div class="mb-3">
              <label class="form-label">第二代下線分潤</label>
              <div class="input-group">
                <input
                  type="number"
                  min="0"
                  class="form-control"
                  v-model.number="profitSharingRatio.gain.rate2"
                />
                <span class="input-group-text">%</span>
              </div>
            </div>
            <div class="mb-3">
              <label class="form-label">第三代下線分潤</label>
              <div class="input-group">
                <input
                  type="number"
                  min="0"
                  class="form-control"
                  v-model.number="profitSharingRatio.gain.rate3"
                />
                <span class="input-group-text">%</span>
              </div>
            </div>
          </div>
          <div class="col-md-3 offset-md-1">
            <div class="mb-3">
              <label class="form-label">經紀人第一代下線分潤</label>
              <div class="input-group">
                <input
                  type="number"
                  min="0"
                  class="form-control"
                  v-model.number="profitSharingRatio.agent"
                />
                <span class="input-group-text">%</span>
              </div>
            </div>
            <div class="mb-3">
              <label class="form-label">合作經紀公司 A</label>
              <div class="input-group">
                <input
                  type="number"
                  min="0"
                  class="form-control"
                  v-model.number="profitSharingRatio.agentCompanyA"
                />
                <span class="input-group-text">%</span>
              </div>
            </div>
            <div class="mb-3">
              <label class="form-label">合作經紀公司 B</label>
              <div class="input-group">
                <input
                  type="number"
                  min="0"
                  class="form-control"
                  v-model.number="profitSharingRatio.agentCompanyB"
                />
                <span class="input-group-text">%</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { db, firestore } from "../../getFireStore";
import { variableService } from "../../services/variableService.js";
import { Modal } from "bootstrap";
import draggable from "vuedraggable";
export default {
  name: "Setting",
  components: {
    draggable,
  },
  data() {
    return {
      customerInfo: {},
      storeAction: "add",
      stores: [],
      nowEditStoreName: "",
      storeName: "",
      profitSharingRatio: {
        basic: {},
        gain: {},
        agent: null,
      },
    };
  },
  methods: {
    // just for init once
    async initMemberRole() {
      const membersRef = firestore.collection(db, "members");
      const q = firestore.query(
        membersRef,
        firestore.orderBy("registerTime", "desc")
      );
      const querySnapshot = await firestore.getDocs(q);

      querySnapshot.forEach((doc) => {
        const data = doc.data();

        if (data["agent"] === true) {
          data["role"] = "agent";
        } else {
          data["role"] = "generally";
        }

        // console.log(data["agent"], data["role"])
        // console.log(data)
        console.log(doc.id);
        firestore.setDoc(firestore.doc(db, "members", doc.id), data);
      });

      console.log("ok");
    },

    async initNumberStageName() {
      if (prompt("password") === "53990298") {
        const membersRef = firestore.collection(db, "members");
        const q = firestore.query(
          membersRef,
          firestore.orderBy("registerTime", "desc")
        );
        const querySnapshot = await firestore.getDocs(q);

        querySnapshot.forEach((doc) => {
          const data = doc.data();

          if (data["store"]) {
            data["numberStageName"] = data["store"];
          } else {
            data["numberStageName"] = "";
          }

          data["store"] = "";

          // console.log(data)
          firestore.setDoc(firestore.doc(db, "members", doc.id), data);
        });

        alert("ok");
      } else {
        alert("error");
      }
    },

    async onStoresSrot() {
      await firestore
        .setDoc(firestore.doc(db, "setting", "gx_stores"), {
          stores: this.stores,
        })
        .then(() => {
          // alert("客戶資訊 儲存完畢");
          console.log("sorted..");
        });
    },
    async delStore() {
      if (
        confirm("您確定要刪除該服務店家嗎? 若已經會員已設定此店家則會變為空值")
      ) {
        const docSnap = await firestore.getDoc(
          firestore.doc(db, "setting", "gx_stores")
        );

        if (docSnap.exists()) {
          let gx_stores = docSnap.data();

          gx_stores["stores"] = gx_stores["stores"].filter(
            (item) => item !== this.nowEditStoreName
          );

          // 使用batch更新服務店家名稱與每個會員的服務店家名稱
          const batch = firestore.writeBatch(db);

          try {
            const collection_ref = firestore.collection(db, "members");

            let q = firestore.query(
              collection_ref,
              firestore.where("store", "==", this.nowEditStoreName)
            );

            const querySnapshot = await firestore.getDocs(q);

            querySnapshot.forEach((doc) => {
              batch.update(firestore.doc(db, "members", doc.id), { store: "" });
            });

            batch.set(firestore.doc(db, "setting", "gx_stores"), gx_stores);

            await batch.commit();

            await variableService.init_allMember();
          } catch (error) {
            alert("更新失敗");
          }

          // await firestore.setDoc(
          //   firestore.doc(db, "setting", "gx_stores"),
          //   gx_stores
          // );

          this.fetchStores();
          this.hideModal();
        }
      }
    },
    async saveStore() {
      if (!this.storeName) {
        alert("請輸入服務店家名稱");
        return false;
      }

      const docSnap = await firestore.getDoc(
        firestore.doc(db, "setting", "gx_stores")
      );

      if (docSnap.exists()) {
        const gx_stores = docSnap.data();

        if (
          (this.storeAction === "add" &&
            gx_stores["stores"].includes(this.storeName)) ||
          (this.storeAction === "edit" &&
            this.storeName != this.nowEditStoreName &&
            gx_stores["stores"].includes(this.storeName))
        ) {
          alert("已經有存在的服務店家名稱，請重新命名");
          return false;
        }

        let changeStoreName = false;

        if (this.storeAction === "add") {
          gx_stores["stores"].push(this.storeName);
        } else if (this.storeAction === "edit") {
          if (this.storeName != this.nowEditStoreName) {
            const store_index = gx_stores["stores"].indexOf(
              this.nowEditStoreName
            );

            gx_stores["stores"][store_index] = this.storeName;

            changeStoreName = true;
          }
        }

        // 使用batch更新服務店家名稱與每個會員的服務店家名稱
        const batch = firestore.writeBatch(db);

        try {
          // 如果有更改服務店家名稱則需要一起更改有設定該服務店家名稱的會員
          if (changeStoreName === true) {
            const collection_ref = firestore.collection(db, "members");

            let q = firestore.query(
              collection_ref,
              firestore.where("store", "==", this.nowEditStoreName)
            );

            const querySnapshot = await firestore.getDocs(q);

            querySnapshot.forEach((doc) => {
              batch.update(firestore.doc(db, "members", doc.id), {
                store: this.storeName,
              });
            });
          }

          batch.set(firestore.doc(db, "setting", "gx_stores"), gx_stores);

          await batch.commit();

          await variableService.init_allMember();
        } catch (error) {
          alert("更新失敗");
        }

        this.fetchStores();
        this.hideModal();
        this.storeName = "";
      }
    },
    showModal(mode, nowEditStoreName = null) {
      this.storeAction = mode;
      this.nowEditStoreName = nowEditStoreName;

      if (mode === "edit") {
        this.storeName = nowEditStoreName;
      } else {
        this.storeName = "";
      }

      this.myModal = new Modal(document.getElementById("exampleModal"), {});
      this.myModal.show();
    },
    hideModal() {
      this.myModal.hide();
    },
    async fetchStores() {
      await firestore
        .getDoc(firestore.doc(db, "setting", "gx_stores"))
        .then((doc) => {
          if (doc.data()) {
            this.stores = doc.data()["stores"];
          }
        });
    },
    async fetchData() {
      await firestore
        .getDoc(firestore.doc(db, "setting", "gx_customerInfo"))
        .then((doc) => {
          if (doc.data()) {
            this.customerInfo = doc.data();
          }
        });

      await firestore
        .getDoc(firestore.doc(db, "setting", "gx_profitSharingRatio"))
        .then((doc) => {
          if (doc.data()) {
            this.profitSharingRatio = doc.data();
          }
        });
    },
    async saveCustomerInfo() {
      await firestore
        .setDoc(
          firestore.doc(db, "setting", "gx_customerInfo"),
          this.customerInfo
        )
        .then(() => {
          alert("客戶資訊 儲存完畢");
        });
    },
    async saveRatio() {
      await firestore
        .setDoc(
          firestore.doc(db, "setting", "gx_profitSharingRatio"),
          this.profitSharingRatio
        )
        .then(() => {
          alert("分潤比例 儲存完畢");
        });
    },
  },
  mounted() {},
  created() {
    this.fetchData();
    this.fetchStores();
  },
};
</script>

<style scoped>
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.handle {
  cursor: move;
}
</style>
